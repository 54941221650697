<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogFormVisible"
      width="550px"
      @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="国家" prop="country_id">
        <el-select v-model="form.country_id" size="small" placeholder="请选择国家" style="width: 100%">
          <el-option v-for="(item,index) in countriesArr" :key="index" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="税率" prop="tax_rate">
        <el-input type="number" min="0.1" max="100" v-model="form.tax_rate" size="small" placeholder="请输入税率">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="save" size="small">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiGetCountrysList, apiOperationRate} from "@/request/api";

export default {
  name: 'rateEdit',
  data() {
    return {
      countriesArr: [],
      form: {
        country_id: '',
        tax_rate: '',
        state: 0,
      },
      rules: {
        country_id: [
          {required: true, trigger: 'blur', message: '国家不能为空'},
        ],
        tax_rate: [
          {required: true, trigger: 'blur', message: '税率不能为空'},
        ],
      },
      title: '',
      dialogFormVisible: false,
    }
  },
  created() {
    this.getCountries()
  },
  methods: {
    //获取国家列表（下拉框）
    getCountries() {
      apiGetCountrysList().then(res => {
        if (res.code == 200) {
          this.countriesArr = res.data
        }
      })
    },
    showEdit(row) {
      if (!row) {
        this.title = '添加'
      } else {
        this.title = '修改'
        const tempObj = Object.assign({}, row)
        this.form = {
          id: tempObj.id,
          country_id: tempObj.country_id,
          tax_rate: tempObj.tax_rate,
          state: tempObj.state,
        }
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          apiOperationRate(this.form).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$message.error(message)
              this.close()
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

