<template>
  <el-card class="box-card">
    <el-col :span="12">
      <el-button icon="el-icon-plus" type="warning" @click="handleEdit" size="small">
        新增
      </el-button>
    </el-col>
    <el-col :span="12" style="text-align: right">
      <el-select
          v-model="queryForm.id"
          clearable
          placeholder="请选择国家"
          style="margin-right: 10px"
          size="small"
          @change="monitoringVal"
      >
        <el-option
            v-for="item in countriesArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
        />
      </el-select>
      <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
        查询
      </el-button>
    </el-col>

    <el-table
        v-loading="listLoading"
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          prop="country_obj.name"
          label="国家">
      </el-table-column>
      <el-table-column
          align="center"
          prop="tax_rate"
          label="税率"
      >
        <template #default="{ row }">
          <span v-if="row.tax_rate">{{ row.tax_rate }}%</span>
          <span v-else>-%</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="状态"
          prop="state"
          show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-tooltip
              :content="row.state == 1 ? '点击禁用' : '点击启用'"
              :enterable="false"
              placement="top"
          >
            <el-switch
                v-model="row.state"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :value="true"
                @change="changeStatus(row, row.state)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Edit ref="Edit" @fetch-data="fetchData"/>
  </el-card>
</template>
<script>
import Edit from './components/rateEdit'
import {
  apiDeleteRateList, apiGetCountrysList,
  apiGetRateList, apiOperationRate,
} from "@/request/api";

export default {
  name: 'ratelist',
  components: {Edit},
  data() {
    return {
      countriesArr: [],
      listLoading: false,
      queryForm: {id: '',},
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
    this.getCountries()
  },
  mounted() {
  },
  methods: {
    monitoringVal(event) {
      if (!event) {
        this.fetchData()
      }
    },
    //获取国家列表（下拉框）
    getCountries() {
      apiGetCountrysList().then(res => {
        if (res.code == 200) {
          this.countriesArr = res.data
        }
      })
    },
    changeStatus(row, lock) {
      apiOperationRate({
        id: row.id,
        country_id: row.country_id,
        tax_rate: row.tax_rate,
        state: lock,
      }).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.fetchData()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    // 删除税率
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteRateList({id: row.id}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('未选中任何行')
        return false
      }
    },
    queryData() {
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetRateList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data
        }
      })
      this.listLoading = false
    },
  }
}

</script>

